export const searchCriteriaTypes = {
  actualAgeMin: 'ACTUAL AGE MIN',
  actualAgeMax: 'ACTUAL AGE MAX',
  all: 'ALL',
  canPlayAgeMin: 'CAN PLAY AGE MIN',
  canPlayAgeMax: 'CAN PLAY AGE MAX',
  baseLocations: 'BASE LOCATION',
  ethnicities: 'ETHNICITY',
  genders: 'GENDER',
  hairColors: 'HAIR COLOR',
  interests: 'INTERESTS',
  nationalities: 'NATIONALITY',
  parent: 'PARENT',
};

export const initialCriteriaState = {
  all: false,
  actualAgeMin: '',
  actualAgeMax: '',
  canPlayAgeMin: '',
  canPlayAgeMax: '',
  baseLocations: [],
  ethnicities: [],
  genders: [],
  hairColors: [],
  interests: [],
  nationalities: [],
  parent: [],
};

export const searchCriteriaReducer = (state, action) => {
  switch (action.type) {
    case searchCriteriaTypes.actualAgeMin:
      return {
        ...state,
        actualAgeMin: action.payload,
      };
    case searchCriteriaTypes.actualAgeMax:
      return {
        ...state,
        actualAgeMax: action.payload,
      };
    case searchCriteriaTypes.all:
      return {
        ...state,
        all: action.payload,
      };
    case searchCriteriaTypes.canPlayAgeMin:
      return {
        ...state,
        canPlayAgeMin: action.payload,
      };
    case searchCriteriaTypes.canPlayAgeMax:
      return {
        ...state,
        canPlayAgeMax: action.payload,
      };
    case searchCriteriaTypes.baseLocations:
      // handle 'select all'
      if (action.payload.length > 1) {
        const selected = action.payload.length === state.baseLocations.length ? [] : action.payload;
        return { ...state, baseLocations: selected };
      }
      // handle 'select one'
      else {
        const selected = action.payload[0];
        if (state.baseLocations.includes(selected)) {
          return {
            ...state,
            baseLocations: state.baseLocations.filter((each) => each !== selected),
          };
        } else {
          return {
            ...state,
            baseLocations: [...state.baseLocations, selected],
          };
        }
      }
    case searchCriteriaTypes.ethnicities:
      // handle 'select all'
      if (action.payload.length > 1) {
        const selected = action.payload.length === state.ethnicities.length ? [] : action.payload;
        return { ...state, ethnicities: selected };
      }
      // handle 'select one'
      else {
        const selected = action.payload[0];
        if (state.ethnicities.includes(selected)) {
          return {
            ...state,
            ethnicities: state.ethnicities.filter((each) => each !== selected),
          };
        } else {
          return {
            ...state,
            ethnicities: [...state.ethnicities, selected],
          };
        }
      }
    case searchCriteriaTypes.genders:
      // handle 'select all'
      if (action.payload.length > 1) {
        const selected = action.payload.length === state.genders.length ? [] : action.payload;
        return { ...state, genders: selected };
      }
      //handle 'select one'
      else {
        const selected = action.payload[0];
        if (state.genders.includes(selected)) {
          return {
            ...state,
            genders: state.genders.filter((each) => each !== selected),
          };
        } else {
          return {
            ...state,
            genders: [...state.genders, selected],
          };
        }
      }
    case searchCriteriaTypes.hairColors:
      // handle 'select all'
      if (action.payload.length > 1) {
        const selected = action.payload.length === state.hairColors.length ? [] : action.payload;
        return { ...state, hairColors: selected };
      }
      // handle 'select one'
      else {
        const selected = action.payload[0];
        if (state.hairColors.includes(selected)) {
          return {
            ...state,
            hairColors: state.hairColors.filter((each) => each !== selected),
          };
        } else {
          return {
            ...state,
            hairColors: [...state.hairColors, selected],
          };
        }
      }
    case searchCriteriaTypes.interests:
      // handle 'select all'
      if (action.payload.length > 1) {
        const selected = action.payload.length === state.interests.length ? [] : action.payload;
        return { ...state, interests: selected };
      }
      // handle 'select one'
      else {
        const selected = action.payload[0];
        if (state.interests.includes(selected)) {
          return {
            ...state,
            interests: state.interests.filter((each) => each !== selected),
          };
        } else {
          return {
            ...state,
            interests: [...state.interests, selected],
          };
        }
      }
    case searchCriteriaTypes.nationalities:
      // handle 'select all'
      if (action.payload.length > 1) {
        const selected = action.payload.length === state.nationalities.length ? [] : action.payload;
        return { ...state, nationalities: selected };
      }
      // handle 'select one'
      else {
        const selected = action.payload[0];
        if (state.nationalities.includes(selected)) {
          return {
            ...state,
            nationalities: state.nationalities.filter((each) => each !== selected),
          };
        } else {
          return {
            ...state,
            nationalities: [...state.nationalities, selected],
          };
        }
      }
    case searchCriteriaTypes.parent:
      // handle 'select all'
      if (action.payload.length > 1) {
        const selected = action.payload.length === state.parent.length ? [] : action.payload;
        return { ...state, parent: selected };
      }
      // handle 'select one'
      else {
        const selected = action.payload[0];
        if (state.parent.includes(selected)) {
          return {
            ...state,
            parent: state.parent.filter((each) => each !== selected),
          };
        } else {
          return {
            ...state,
            parent: [...state.parent, selected],
          };
        }
      }

    default:
      return state;
  }
};
