import React from 'react';

import { ToggleSwitch } from '../../components/toggleSwitch/ToggleSwitch';

export const AppliedFilters = ({ filters, handleToggle }) => {
  const appliedFilters = (filters) => {
    const filterMap = {};
    if (filters.actualAgeMin && filters.actualAgeMax) {
      filterMap['Actual Age'] = [`${filters.actualAgeMin} - ${filters.actualAgeMax}`];
    }
    if (filters.canPlayAgeMin && filters.canPlayAgeMax) {
      filterMap['Can Play Age'] = [`${filters.canPlayAgeMin} - ${filters.canPlayAgeMax}`];
    }
    if (filters.baseLocations.length > 0) {
      filterMap['Base Location'] = filters.baseLocations;
    }
    if (filters.ethnicities.length > 0) {
      filterMap['Ethnicity'] = filters.ethnicities;
    }
    if (filters.genders.length > 0) {
      filterMap['Gender'] = filters.genders;
    }
    if (filters.hairColors.length > 0) {
      filterMap['Hair Color'] = filters.hairColors;
    }
    if (filters.interests.length > 0) {
      filterMap['Interests'] = filters.interests;
    }
    if (filters.nationalities.length > 0) {
      filterMap['Nationality'] = filters.nationalities;
    }
    if (filters.parent.length > 0) {
      filterMap['Is Parent'] = filters.parent;
    }
    return filterMap;
  };

  return (
    <div style={styles.container}>
      <div style={styles.headerText}>Criteria Applied</div>
      <div style={styles.filterContainer}>
        <div style={styles.filterHeaderText}>Criteria Match</div>
        <div style={styles.switchContainer}>
          <div style={styles.switchText}>100% Match</div>
          <ToggleSwitch initialToggleState={filters.all} onChange={handleToggle} />
        </div>
      </div>
      {Object.entries(appliedFilters(filters)).map(([filterLabel, filterValues]) => {
        return (
          <div key={filterLabel} style={styles.filterContainer}>
            <div style={styles.filterHeaderText}>{filterLabel}</div>
            {filterValues.map((filter) => (
              <div key={filter} style={styles.filterText}>
                {filter}
              </div>
            ))}
          </div>
        );
      })}
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: '40px 20px 0 20px',
  },
  headerText: {
    fontSize: '18px',
    fontWeight: '500',
    padding: '10px',
  },
  filterContainer: {
    padding: '10px',
    width: '100%',
  },
  filterHeaderText: {
    fontSize: '16px',
    fontWeight: '600',
    marginBottom: '2px',
  },
  filterText: {
    marginLeft: '20px',
    fontSize: '14px',
    fontWeight: '400',
  },
  switchText: {
    fontSize: '14px',
    fontWeight: '400',
    marginLeft: '20px',
    marginRight: '8em',
  },
  switchContainer: {
    display: 'flex',
  },
};
