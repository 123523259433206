import React, { useState, useEffect, useCallback } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { MailOutlined, MenuOutlined as ExtendedView } from '@ant-design/icons';

import { Spinner } from 'components';

import { AppliedFilters } from './applied-filters';
import { findClients } from '../../data/clients-dao';
import ClientRow from './client-row';
import { CondensedView } from './condensed-view';

const styles = {
  container: {
    paddingTop: '50px',
  },
  viewButton: {
    alignItems: 'center',
    display: 'flex',
    cursor: 'pointer',
    marginLeft: '5px',
  },
  activeView: {
    background: 'white',
    border: 'black 1px solid',
  },
  emailLink: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    color: 'black',
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: '1fr 3fr 1fr',
  },
  leftNavContainer: {
    paddingTop: '15px',
    height: '100%',
  },
  backButtonContainer: {
    display: 'flex',
    padding: '20px 20px 0 20px',
  },
  backButton: {
    border: 'none',
    backgroundColor: '#F5F5F5',
    fontWeight: 700,
    fontSize: '16px',
  },
  contentContainer: {
    paddingTop: '15px',
    height: '100%',
  },
  topRightMenuContainer: {
    display: 'flex',
    flexDirection: 'row-reverse',
    paddingTop: '20px',
  },
  topLeftMenuContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    padding: 10,
  },
  selectAllContainer: {
    flex: 0,
    flexDirection: 'column',
    marginRight: 10,
  },
  resultsCount: {
    flex: 1,
    flexDirection: 'column',
  },
  match: {
    flex: 0,
    flexDirection: 'column',
  },
  searchResults: {
    textAlign: 'center',
    fontWeight: '700',
    paddingTop: 100,
    paddingBottom: 100,
    fontSize: 20,
  },
  spinnerContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '800px',
  },
};

export const SearchResults = ({ dispatch = {}, filters = {}, handleBack, handleToggle }) => {
  const [selectedClients, setSelectedClients] = useState({});
  const [clients, setClients] = useState([]);
  const [total, setTotal] = useState(0);
  const [hasMore, setHasMore] = useState(false);
  const [view, setView] = useState('expanded');
  const [isLoading, setIsLoading] = useState(true);

  const handleFindClients = useCallback(
    (skip) => {
      findClients(dispatch, filters, skip).then((results) => {
        setClients(results.clients);
        setTotal(results.total);
        setHasMore(results.hasMore);
        setIsLoading(false);
      });
    },
    [dispatch, filters]
  );

  useEffect(() => {
    window.scrollTo(0, 0);
    setIsLoading(true);
    handleFindClients();
  }, [handleFindClients]);

  const selectClient = (client) => {
    const newClient = { [client._id]: client };
    setSelectedClients({ ...selectedClients, ...newClient });
  };
  const deselectClient = (client) => {
    // make a copy so we pick up the update
    let copiedState = Object.assign({}, selectedClients);
    delete copiedState[client._id];
    setSelectedClients(copiedState);
  };

  const emailBody = encodeURIComponent(
    Object.values(selectedClients)
      .map(({ name }) => name)
      .join('\n')
  );

  const condensedViewStyle = view === 'condensed' ? { ...styles.viewButton, ...styles.activeView } : styles.viewButton;
  const expandedViewStyle =
    view === 'expanded'
      ? { ...styles.viewButton, ...styles.activeView, ...{ marginLeft: '20px' } }
      : { ...styles.viewButton, ...{ marginLeft: '20px' } };

  return (
    <>
      {isLoading ? (
        <div style={styles.spinnerContainer}>
          <Spinner />
        </div>
      ) : (
        <div style={styles.grid}>
          <div style={styles.leftNavContainer}>
            <div style={styles.backButtonContainer}>
              <button onClick={handleBack} style={styles.backButton}>
                {'< Back to Search'}
              </button>
            </div>
            <AppliedFilters filters={filters} handleToggle={handleToggle} />
          </div>
          <div style={styles.contentContainer}>
            {clients && clients.length > 0 ? (
              <>
                <div style={styles.topRightMenuContainer}>
                  <CondensedView style={condensedViewStyle} onClick={() => setView('condensed')} />
                  <ExtendedView style={expandedViewStyle} onClick={() => setView('expanded')} />
                  <a
                    style={styles.emailLink}
                    href={`mailto:?body=${emailBody}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <MailOutlined style={{ marginRight: '5px' }} />
                    Email
                  </a>
                </div>
                <hr />
                <div style={styles.topLeftMenuContainer}>
                  <div style={styles.selectAllContainer}>
                    <input type="checkbox" disabled={total === 0} />
                  </div>
                  <div style={styles.resultsCount}>{total.toLocaleString()} Results</div>
                  <div style={styles.match}>Match</div>
                </div>
                <InfiniteScroll
                  pageStart={0}
                  initialLoad={false}
                  loadMore={() => handleFindClients(clients.length)}
                  loader={
                    hasMore && (
                      <div style={{ fontSize: 25 }} key="inf">
                        Loading...
                      </div>
                    )
                  }
                  hasMore={hasMore}
                >
                  {clients.map((client) => (
                    <ClientRow
                      client={client}
                      key={client._id}
                      select={selectClient}
                      deselect={deselectClient}
                      expanded={view === 'expanded'}
                    />
                  ))}
                </InfiniteScroll>
              </>
            ) : (
              <div style={styles.searchResults}>Sorry, we couldn't find any results matching the criteria</div>
            )}
          </div>
        </div>
      )}
    </>
  );
};
