import React, { useState, useReducer, useEffect } from 'react';
import { connect } from 'react-redux';

import { Branding } from 'components';

import { SearchCriteria } from '../../containers/search-criteria/searchCriteria';
import { SearchResults } from '../../containers/search-results';
import { SubmissionTypes } from '../submission-types/submissionTypes';
import { initialCriteriaState, searchCriteriaReducer, searchCriteriaTypes } from '../../reducers/searchCriteriaReducer';
import submissionsSymbol from '../../assets/logo/submissions_transparent_bg.png';

import { datadogRum } from '@datadog/browser-rum';

// Flag used to register what User is active in the application for DataDog.
let DATA_DOG_SET = false;

const SHOW_BANNER = process.env.REACT_APP_SHOW_BANNER;

const VIEWS = { filterView: 'FILTER_VIEW', resultsView: 'RESULTS_VIEW' };

const Submissions = ({ dispatch, user }) => {
  const [filterState, filterDispatch] = useReducer(searchCriteriaReducer, initialCriteriaState);

  const [view, setView] = useState(VIEWS.filterView);
  const [showCriteria, setShowCriteria] = useState(false);
  const [submissionTypes, setSubmissionTypes] = useState([]);

  useEffect(() => {
    if (!datadogRum.getInitConfiguration()) {
      DATA_DOG_SET = true;
    }
  }, []);

  useEffect(() => {
    if (user && user.azure_id && !DATA_DOG_SET) {
      datadogRum.setUser({
        id: user.azure_id,
        name: `${user.last_name}, ${user.first_name}`,
        email: user.email,
      });
      datadogRum.startSessionReplayRecording();
      DATA_DOG_SET = true;
    }
  }, [user]);

  return (
    <div style={styles.container}>
      <Branding text="Submissions" symbol={submissionsSymbol} showBanner={SHOW_BANNER} />
      {view === VIEWS.filterView && (
        <div style={styles.contentContainer}>
          <SubmissionTypes
            showCriteria={showCriteria}
            setShowCriteria={setShowCriteria}
            submissionTypes={submissionTypes}
            setSubmissionTypes={setSubmissionTypes}
          />
          {showCriteria && (
            <SearchCriteria
              dispatch={dispatch}
              submissionTypes={submissionTypes}
              state={filterState}
              dispatchCriteria={filterDispatch}
              handleSearch={() => setView(VIEWS.resultsView)}
            />
          )}
        </div>
      )}
      {view === VIEWS.resultsView && (
        <SearchResults
          dispatch={dispatch}
          filters={filterState}
          handleBack={() => setView(VIEWS.filterView)}
          handleToggle={(x) => filterDispatch({ type: searchCriteriaTypes.all, payload: x })}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

const withState = connect(mapStateToProps);

const styles = {
  container: {
    minWidth: '800px',
  },
  contentContainer: {
    margin: 'auto',
    padding: '15px',
    width: '75%',
    height: '100%',
  },
};

export default withState(Submissions);
