import React from 'react';

import ClientTeam from './client-team';

const BASE_PROFILE_URL = process.env.REACT_APP_PROFILE_URL;

const ClientLink = ({ client }) => {
  const { name, _id } = client;
  return (
    <a style={{ color: 'black' }} target="_blank" rel="noopener noreferrer" href={`${BASE_PROFILE_URL}/profile/${_id}`}>
      {name}
    </a>
  );
};

const ClientRow = ({ client, select, deselect, expanded }) => {
  const totalFilters = Object.keys(client.matches || {}).length;
  const matches = client.matchTotal || 0;
  const missing = Object.keys(client.matches || {}).filter((key) => {
    return (client.matches || {})[key] !== 1;
  });
  return (
    <div
      style={{ display: 'flex', flex: 1, flexDirection: 'row', backgroundColor: '#FFF', padding: 10, marginBottom: 2 }}
    >
      <div style={{ flex: 0, flexDirection: 'column', marginRight: 10 }}>
        <input type="checkbox" onChange={({ target }) => (target.checked ? select(client) : deselect(client))} />
      </div>
      <div style={{ flex: 1, flexDirection: 'column' }}>
        <span style={{ fontWeight: 700 }}>
          <ClientLink client={client} />
        </span>
        {expanded && <ClientTeam client={client} />}
        {expanded && missing.length > 0 && (
          <div>
            Missing:{' '}
            {missing.map((field, index) => (
              <span key={index} style={{ marginRight: 6, textDecoration: 'line-through' }}>
                {field}
                {index + 1 < missing.length ? ',' : ''}
              </span>
            ))}
          </div>
        )}
      </div>
      <div style={{ flex: 0, flexDirection: 'column', whiteSpace: 'nowrap' }}>
        {matches} / {totalFilters}
      </div>
    </div>
  );
};
export default ClientRow;
