import { getClients } from 'julius-frontend-store';
import filterTransformer from './filter-transformer';
const HEADER_PAGE_MORE = 'Pagination-More';
const HEADER_PAGE_TOTAL = 'Pagination-Total';
const RESULTS_PAGE_SIZE = 100;
export const findClients = (dispatch, filters, skip = 0) => {
  const searchFilters = filterTransformer(filters);
  const clientSearch = dispatch(getClients(searchFilters, skip, RESULTS_PAGE_SIZE)).then((results) => {
    if (results.status !== 200) {
      return Promise.reject(`Status: ${results.status}`);
    }
    const hasMore = results.headers.get(HEADER_PAGE_MORE) === true.toString() ? true : false;
    const total = parseInt(results.headers.get(HEADER_PAGE_TOTAL));
    const clients = results ? results.body : [];
    return { clients, total, hasMore, searchFilters };
  });

  return clientSearch;
};

export default { findClients };
