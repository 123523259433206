import React from 'react';

import './dualInput.css';

export const DualInput = ({
  leftPlaceholder,
  leftValue,
  handleChangeLeft,
  rightPlaceholder,
  rightValue,
  handleChangeRight,
  type = 'text',
}) => {
  return (
    <div style={styles.dualInputContiner}>
      <input
        type={type}
        style={styles.dualInput}
        placeholder={leftPlaceholder}
        value={leftValue}
        onChange={handleChangeLeft}
      />
      <input
        type={type}
        style={styles.dualInput}
        placeholder={rightPlaceholder}
        value={rightValue}
        onChange={handleChangeRight}
      />
    </div>
  );
};

const styles = {
  dualInput: {
    width: '80px',
    height: '35px',
    marginRight: '10px',
  },
  dualInputContiner: {
    display: 'flex',
    marginRigth: '25%',
  },
};
