import React from 'react';
import Icon from '@ant-design/icons';

const CondensedViewSvg = () => (
  <svg width="1em" height="1em" fill="currentColor" viewBox="64 64 896 896">
    <path d="M 906.229 77.014 L 122.229 77.014 C 117.829 77.014 114.229 79.251 114.229 81.985 L 114.229 121.755 C 114.229 124.49 117.829 126.727 122.229 126.727 L 906.229 126.727 C 910.629 126.727 914.229 124.49 914.229 121.755 L 914.229 81.985 C 914.229 79.251 910.629 77.014 906.229 77.014 Z M 906.229 464.774 L 122.229 464.774 C 117.829 464.774 114.229 467.012 114.229 469.746 L 114.229 509.515 C 114.229 512.25 117.829 514.488 122.229 514.488 L 906.229 514.488 C 910.629 514.488 914.229 512.25 914.229 509.515 L 914.229 469.746 C 914.229 467.012 910.629 464.774 906.229 464.774 Z M 906.229 270.895 L 122.229 270.895 C 117.829 270.895 114.229 273.132 114.229 275.865 L 114.229 315.637 C 114.229 318.371 117.829 320.608 122.229 320.608 L 906.229 320.608 C 910.629 320.608 914.229 318.371 914.229 315.637 L 914.229 275.865 C 914.229 273.132 910.629 270.895 906.229 270.895 Z" />
    <path d="M 905.391 464.853 L 121.391 464.853 C 116.991 464.853 113.391 467.09 113.391 469.824 L 113.391 509.594 C 113.391 512.329 116.991 514.566 121.391 514.566 L 905.391 514.566 C 909.791 514.566 913.391 512.329 913.391 509.594 L 913.391 469.824 C 913.391 467.09 909.791 464.853 905.391 464.853 Z M 905.391 852.613 L 121.391 852.613 C 116.991 852.613 113.391 854.851 113.391 857.585 L 113.391 897.354 C 113.391 900.089 116.991 902.327 121.391 902.327 L 905.391 902.327 C 909.791 902.327 913.391 900.089 913.391 897.354 L 913.391 857.585 C 913.391 854.851 909.791 852.613 905.391 852.613 Z M 905.391 658.734 L 121.391 658.734 C 116.991 658.734 113.391 660.971 113.391 663.704 L 113.391 703.476 C 113.391 706.21 116.991 708.447 121.391 708.447 L 905.391 708.447 C 909.791 708.447 913.391 706.21 913.391 703.476 L 913.391 663.704 C 913.391 660.971 909.791 658.734 905.391 658.734 Z" />
  </svg>
);

export const CondensedView = (props) => <Icon component={CondensedViewSvg} {...props} />;
