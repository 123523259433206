import React from 'react';

import { Multiselect } from '../../components/multiselect/Multiselect';

const SUBMISSION_TYPES = ['Endorsement', 'Voiceover'];

export const SubmissionTypes = ({ showCriteria, setShowCriteria, submissionTypes, setSubmissionTypes }) => {
  function handleSelect(selected) {
    if (selected.length > 1) {
      submissionTypes.length === selected.length ? setSubmissionTypes([]) : setSubmissionTypes(selected);
    } else {
      const selectedType = selected[0];
      if (submissionTypes.includes(selectedType)) {
        const updatedTypes = submissionTypes.filter((each) => each !== selectedType);
        updatedTypes.length === 0 && setShowCriteria(false);
        setSubmissionTypes(updatedTypes);
      } else {
        setSubmissionTypes(submissionTypes.concat([selectedType]));
      }
    }
  }

  function handleClick() {
    if (showCriteria) setSubmissionTypes([]);
    setShowCriteria((prevShowCriteria) => submissionTypes.length > 0 && !prevShowCriteria);
  }

  const isSelectDisabled = !showCriteria && submissionTypes.length === 0;
  const selectButtonStyle = isSelectDisabled
    ? { ...styles.selectButton, ...styles.disabledSelectButton }
    : styles.selectButton;

  return (
    <>
      <p style={styles.header}>Select the Type(s)</p>
      <div style={styles.contentContainer}>
        <div style={styles.dropdownContainer}>
          <h5>Type</h5>
          <Multiselect
            title="Select Type"
            options={SUBMISSION_TYPES}
            onSelect={handleSelect}
            selectedOptions={submissionTypes}
            selectAll
          />
        </div>
        <button
          style={showCriteria ? styles.clearButton : selectButtonStyle}
          onClick={handleClick}
          disabled={isSelectDisabled}
        >
          {showCriteria ? 'Clear All' : 'Select'}
        </button>
      </div>
    </>
  );
};

const styles = {
  header: {
    fontSize: '21px',
    fontWeight: '400',
    margin: '20px 0',
  },
  contentContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    backgroundColor: '#FFF',
    padding: '20px',
  },
  dropdownContainer: {
    flexDirection: 'column',
    width: '33%',
  },
  selectButton: {
    width: '96px',
    height: '35px',
    backgroundColor: '#000',
    borderRadius: '2px',
    borderWidth: 0,
    color: '#FFF',
    fontSize: '16px',
  },
  clearButton: {
    width: '96px',
    height: '35px',
    backgroundColor: '#FFF',
    borderRadius: '2px',
    borderWidth: 0,
    color: '#000',
    fontSize: '16px',
  },
  disabledSelectButton: {
    backgroundColor: 'grey',
  },
};
