import { datadogRum } from '@datadog/browser-rum';

const HOST_MAP = {
  'submissions.unitedtalent.com': 'prod',
  'submissions.staging.unitedtalent.com': 'staging',
  'submissions.qa.unitedtalent.com': 'dev',
  localhost: 'local',
};

/**
 * Initializes data dog for the appropriate host (environment)
 *
 * @param { string } host:
 * @param { string[] } allowableEnvs: a list of allowable environments to track.
 */
export const initializeDataDog = (host, allowableEnvs) => {
  const env = HOST_MAP[host];
  if (allowableEnvs.includes(env)) {
    datadogRum.init({
      env,
      version: '0.3.1',
      service: 'submissions-united-talent',
      site: 'us3.datadoghq.com',
      applicationId: 'b0fb9233-b967-4403-ab59-3ce6d30ab4f0',
      clientToken: 'pubd8978cc99dff1ce758a0b6979e4a50e3',
      allowedTracingOrigins: ['https://api.unitedtalent.com', /https:\/\/.*\.unitedtalent\.com/],
      sampleRate: 100,
      replaySampleRate: 100,
      trackInteractions: true,
      defaultPrivacyLevel: 'allow',
    });
  }
};
