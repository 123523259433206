import { getClientLocations } from 'julius-frontend-store';

export const getClientsBaseLocations = (dispatch) => {
  const clientSearch = dispatch(getClientLocations())
    .then((results) => {
      return results ? results.body : [];
    })
    .catch((err) => {
      console.error(err);
      return [];
    });

  return clientSearch;
};

export default { getClientsBaseLocations };
