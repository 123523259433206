import 'bootstrap/dist/css/bootstrap.css';
import 'font-awesome/css/font-awesome.min.css';
import 'normalize.css/normalize.css';
import 'react-confirm-alert/src/react-confirm-alert.css';
import 'react-day-picker/lib/style.css';

import './index.css';
// Import CSS before React components.

import * as React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';

import { createStoreWithOptions } from 'julius-frontend-store';
import { OnyxThemeProvider } from 'components';

import Routes from './routes';

import { apiServerUrl } from './support/urls';

import { initializeDataDog } from './support/dataDog';

const store = createStoreWithOptions({ apiServerUrl });

/**
 * Data Dog RUM tracking initialization
 *
 * active host is interrogated against the allowable env flags using this map:
 *    "prod": submissions.unitedtalent.com
 *    "staging": submissions.staging.unitedtalent.com
 *    "dev": submissions.dev.unitedtalent.com
 *    "local": localhost
 */
initializeDataDog(window.location.hostname, ['staging', 'prod']);

function mount(App) {
  return render(
    <Provider store={store}>
      <OnyxThemeProvider>
        <App />
      </OnyxThemeProvider>
    </Provider>,
    document.getElementById('app')
  );
}

mount(Routes);
