import React, { useEffect, useState } from 'react';
import { isEqual } from 'lodash';

import { nationalities, ethnicities, genders, hairColors, personalInterests } from './personalInfo';
import { searchCriteriaTypes, initialCriteriaState } from '../../reducers/searchCriteriaReducer';
import { DualInput } from '../../components/dual-input/DualInput';
import { Multiselect } from '../../components/multiselect/Multiselect';
import { ToggleSwitch } from '../../components/toggleSwitch/ToggleSwitch';
import { getClientsBaseLocations } from '../../data/client-locations-dao';

export const SearchCriteria = ({
  dispatch = {},
  submissionTypes = [],
  state = {},
  dispatchCriteria = {},
  handleSearch,
}) => {
  const [baseLocationOptions, setBaseLocationOptions] = useState([]);

  useEffect(() => {
    getClientsBaseLocations(dispatch).then((results) => {
      setBaseLocationOptions(
        results.map((result) => (result.state ? `${result.city}, ${result.state}` : `${result.city}`))
      );
    });
  }, [dispatch]);

  const searchCriteria = new Set();
  allSearchCriteria(state, dispatchCriteria, baseLocationOptions).map((criteria) =>
    submissionTypes.forEach((submissionType) => {
      if (criteria.submissionTypes.includes(submissionType)) searchCriteria.add(criteria);
    })
  );

  const isSearchDisabled = isEqual(state, initialCriteriaState);

  return (
    searchCriteria.size > 0 && (
      <div style={styles.contentContainer}>
        <div style={styles.grid}>
          <div style={styles.criteriaContainer}>
            <h5>Criteria Match</h5>
            <div style={styles.switchContainer}>
              <p style={styles.switchText}>100% Match</p>
              <ToggleSwitch
                initialToggleState={state.all}
                onChange={(x) => dispatchCriteria({ type: searchCriteriaTypes.all, payload: x })}
              />
            </div>
          </div>
          {[...searchCriteria].map((each) => {
            return (
              <div style={styles.criteriaContainer} key={each.displayName}>
                <h5>{each.displayName}</h5>
                {each.component}
              </div>
            );
          })}
        </div>
        <div style={styles.buttonContainer}>
          <button
            style={isSearchDisabled ? { ...styles.searchButton, ...styles.disabledSearchButton } : styles.searchButton}
            onClick={handleSearch}
            disabled={isSearchDisabled}
          >
            Search
          </button>
        </div>
      </div>
    )
  );
};

const allSearchCriteria = (state, dispatch, baseLocationOptions) => [
  {
    submissionTypes: ['Endorsement', 'Voiceover'],
    displayName: 'Actual Age',
    component: (
      <DualInput
        leftPlaceholder="Age Min"
        leftValue={state.actualAgeMin}
        handleChangeLeft={(x) => dispatch({ type: searchCriteriaTypes.actualAgeMin, payload: x.target.value })}
        rightPlaceholder="Age Max"
        rightValue={state.actualAgeMax}
        handleChangeRight={(x) => dispatch({ type: searchCriteriaTypes.actualAgeMax, payload: x.target.value })}
        type="number"
      />
    ),
  },
  {
    submissionTypes: ['Endorsement', 'Voiceover'],
    displayName: 'Can Play Age',
    component: (
      <DualInput
        leftPlaceholder="Age Min"
        leftValue={state.canPlayAgeMin}
        handleChangeLeft={(x) => dispatch({ type: searchCriteriaTypes.canPlayAgeMin, payload: x.target.value })}
        rightPlaceholder="Age Max"
        rightValue={state.canPlayAgeMax}
        handleChangeRight={(x) => dispatch({ type: searchCriteriaTypes.canPlayAgeMax, payload: x.target.value })}
        type="number"
      />
    ),
  },
  {
    submissionTypes: ['Endorsement', 'Voiceover'],
    displayName: 'Base Location',
    component: (
      <Multiselect
        title="Select Base Location"
        options={baseLocationOptions}
        onSelect={(x) => dispatch({ type: searchCriteriaTypes.baseLocations, payload: x })}
        selectedOptions={state.baseLocations}
        selectAll
        loading={baseLocationOptions.length === 0}
      />
    ),
  },
  {
    submissionTypes: ['Endorsement', 'Voiceover'],
    displayName: 'Ethnicity',
    component: (
      <Multiselect
        title="Select Ethnicity"
        options={ethnicities}
        onSelect={(x) => dispatch({ type: searchCriteriaTypes.ethnicities, payload: x })}
        selectedOptions={state.ethnicities}
        selectAll
      />
    ),
  },
  {
    submissionTypes: ['Endorsement', 'Voiceover'],
    displayName: 'Gender',
    component: (
      <Multiselect
        title="Select Gender"
        options={genders}
        onSelect={(x) => dispatch({ type: searchCriteriaTypes.genders, payload: x })}
        selectedOptions={state.genders}
        selectAll
      />
    ),
  },
  {
    submissionTypes: ['Endorsement'],
    displayName: 'Hair Color',
    component: (
      <Multiselect
        title="Select Hair Color"
        options={hairColors}
        onSelect={(x) => dispatch({ type: searchCriteriaTypes.hairColors, payload: x })}
        selectedOptions={state.hairColors}
        selectAll
      />
    ),
  },
  {
    submissionTypes: ['Endorsement'],
    displayName: 'Interests',
    component: (
      <Multiselect
        title="Select Interests"
        options={personalInterests}
        onSelect={(x) => dispatch({ type: searchCriteriaTypes.interests, payload: x })}
        selectedOptions={state.interests}
        selectAll
      />
    ),
  },
  {
    submissionTypes: ['Endorsement', 'Voiceover'],
    displayName: 'Nationality',
    component: (
      <Multiselect
        title="Select Nationality"
        options={nationalities}
        onSelect={(x) => dispatch({ type: searchCriteriaTypes.nationalities, payload: x })}
        selectedOptions={state.nationalities}
        selectAll
      />
    ),
  },
  {
    submissionTypes: ['Endorsement'],
    displayName: 'Parent',
    component: (
      <Multiselect
        title="Select Parent"
        options={['Yes', 'No']}
        onSelect={(x) => dispatch({ type: searchCriteriaTypes.parent, payload: x })}
        selectedOptions={state.parent}
        selectAll
      />
    ),
  },
];

const styles = {
  buttonContainer: {
    marginTop: '20px',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  contentContainer: {
    backgroundColor: '#FFF',
    marginTop: '2px',
    padding: '20px',
  },
  criteriaContainer: {
    paddingRight: '25%',
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridGap: '20px',
  },
  searchButton: {
    width: '96px',
    height: '40px',
    justifySelf: 'flex-end',
    backgroundColor: '#000',
    borderRadius: '2px',
    borderWidth: 0,
    color: '#FFF',
    fontSize: '16px',
  },
  disabledSearchButton: {
    backgroundColor: 'grey',
  },
  switchContainer: {
    display: 'flex',
  },
  switchText: {
    fontSize: '14px',
    fontWeight: 400,
    marginRight: '25px',
  },
};
