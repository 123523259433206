import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Notifications from 'react-notify-toast';
import { connect } from 'react-redux';

import { errors, notifications } from 'components';
import { colors, Login } from 'julius-frontend-components';
import { PrivateRoute, LoginCallback, redirectToAzure, hasAccess } from 'julius-frontend-store';

import Submissions from './containers/submissions';
import { defaultUrl, apiServerUrl } from './support/urls';

const localVersion = require('./git.json').sha;
const { NewRelease } = notifications;
const { REACT_APP_RELEASE_CHECK_INTERVAL_SECONDS, REACT_APP_RELEASE_CHECK_FORCE_RELOAD } = process.env;
const forceReload = REACT_APP_RELEASE_CHECK_FORCE_RELOAD === true.toString();

class Routes extends Component {
  render() {
    const { props } = this;
    const { dispatch } = props;
    const authorizationCheck = () => {
      const auth = hasAccess('access', 'web', 'submissions');
      return auth;
    };

    const privateRouteProps = {
      dispatch,
      apiServerUrl,
      authorizationCheck,
      forbiddenComponent: errors.Forbidden,
    };

    return (
      <Router>
        <div
          className="routes-container"
          style={{
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: colors.background,
            fontFamily: 'aktiv-grotesk, sans-serif',
          }}
        >
          <Helmet>
            <title>SUBMISSIONS</title>
          </Helmet>
          <Notifications />
          <NewRelease
            currentVersion={localVersion}
            intervalSeconds={REACT_APP_RELEASE_CHECK_INTERVAL_SECONDS}
            forceReload={forceReload}
          />
          <Switch>
            <Route
              path="/login"
              component={() => {
                return <Login defaultUrl={defaultUrl} apiServerUrl={apiServerUrl} redirectToAzure={redirectToAzure} />;
              }}
            />
            <Route path="/login-callback" component={() => LoginCallback(props)} />
            <PrivateRoute exact path="/" component={Submissions} {...privateRouteProps} />
            <Route path="*">{errors.NotFound}</Route>
          </Switch>
        </div>
      </Router>
    );
  }
}

const withState = connect();

export default withState(Routes);
