import React, { useState } from 'react';

export const ToggleSwitch = ({ onChange, initialToggleState = false }) => {
  const [toggleState, setToggleState] = useState(initialToggleState);

  const handleChange = () => {
    setToggleState((prevToggleState) => {
      const newToggleState = !prevToggleState;
      if (onChange) onChange(newToggleState);
      return newToggleState;
    });
  };

  const styles = {
    trackOff: {
      width: '40px',
      height: '20px',
      backgroundColor: '#BFBFBF',
      borderRadius: '20px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      cursor: 'pointer',
    },
    trackOn: {
      width: '40px',
      height: '20px',
      backgroundColor: '#2495D4',
      borderRadius: '20px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      cursor: 'pointer',
    },
    switch: {
      width: '16px',
      height: '16px',
      backgroundColor: '#FFF',
      borderRadius: '16px',
      marginLeft: '2px',
      marginRight: '2px',
    },
    onText: {
      fontSize: '8px',
      color: '#FFF',
      display: !toggleState && 'none',
      marginTop: '15px',
      marginRight: '2px',
    },
    offText: {
      fontSize: '8px',
      color: '#FFF',
      display: toggleState && 'none',
      marginTop: '15px',
      marginLeft: '1px',
    },
  };

  return (
    <div style={toggleState ? styles.trackOn : styles.trackOff} onClick={handleChange}>
      <p style={styles.onText}>ON</p>
      <div style={styles.switch}></div>
      <p style={styles.offText}>OFF</p>
    </div>
  );
};
