import React from 'react';

const BASE_PROFILE_URL = process.env.REACT_APP_PROFILE_URL;

const styles = {
  agentContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
  },
  agentHeader: {
    fontWeight: 600,
    marginRight: 5,
    whiteSpace: 'nowrap',
  },
  agentLinkContainer: {
    marginRight: '3px',
  },
  agentLink: {
    color: 'black',
    marginRight: '3px',
  },
  clientTeamContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    fontSize: '14px',
    marginTop: 8,
  },
};

const AgentLink = ({ agent, last }) => {
  const { name, _id, departments } = agent;
  const displayedDepartments = `${departments.join('/')}${last ? '' : ','}`;
  return (
    <div style={styles.agentLinkContainer}>
      <a style={styles.agentLink} target="_blank" rel="noopener noreferrer" href={`${BASE_PROFILE_URL}/profile/${_id}`}>
        {name}
      </a>
      {departments && `- ${displayedDepartments}`}
    </div>
  );
};

const ClientTeam = ({ client }) => {
  const { agents = [], pointAgents = [] } = client;
  return (
    (agents.length > 0 || pointAgents.length > 0) && (
      <div style={styles.clientTeamContainer}>
        <div style={styles.agentContainer}>Client Team</div>
        {pointAgents.length > 0 && (
          <div style={styles.agentContainer}>
            <span style={styles.agentHeader}>Point Agents: </span>
            {pointAgents.map((pointAgent, index) => (
              <AgentLink key={index} agent={pointAgent} last={pointAgents.length - 1 === index} />
            ))}
          </div>
        )}
        {agents.length > 0 && (
          <div style={styles.agentContainer}>
            <span style={styles.agentHeader}>Agents:</span>
            {agents.map((agent, index) => (
              <AgentLink key={index} agent={agent} last={agents.length - 1 === index} />
            ))}
          </div>
        )}
      </div>
    )
  );
};
export default ClientTeam;
