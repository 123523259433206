import React, { useState, useEffect } from 'react';

import { Select } from 'julius-frontend-components';

const DropdownOptions = ({ label, checked }) => {
  const styles = {
    input: { padding: '2px' },
    label: { marginLeft: '5px' },
  };

  return (
    <>
      <input readOnly name={label} type="checkbox" style={styles.input} checked={checked} />
      <label htmlFor={label} style={styles.label}>
        {label}
      </label>
    </>
  );
};

export const Multiselect = ({ title, options, onSelect, selectedOptions, selectAll, loading = false }) => {
  const [multiselectOptions, setMultiselectOptions] = useState([]);

  useEffect(() => {
    // The Select component requires an array of objects with key, content, and onClick properties
    const formattedOptions = options.map((option) => ({
      key: option,
      content: <DropdownOptions label={option} checked={selectedOptions.includes(option)} />,
      onClick: () => onSelect([option]),
    }));

    if (selectAll) {
      const allOptions = {
        key: 'All',
        content: (
          <DropdownOptions label="All" checked={options.length > 0 && options.length === selectedOptions.length} />
        ),
        onClick: () => onSelect(options),
      };
      setMultiselectOptions([allOptions, ...formattedOptions]);
    } else {
      return setMultiselectOptions(formattedOptions);
    }
  }, [onSelect, options, selectAll, selectedOptions]);

  if (loading) return <Select title="Loading..." items={[]} chevronDisabled />;

  return (
    <>
      <Select title={title} items={multiselectOptions} dropdownStyle={{ padding: '5px 10px' }} />
      {selectedOptions.map((selectedOption) => (
        <span key={selectedOption} style={{ marginRight: '10px' }} onClick={() => onSelect([selectedOption])}>
          <DropdownOptions label={selectedOption} checked />
        </span>
      ))}
    </>
  );
};
