export const filterTransformer = (filters) => {
  const transformed = { groups: [] };
  transformed.all = !!filters.all;
  filters.baseLocations.length > 0 && transformed.groups.push({ text: 'Base Location', items: filters.baseLocations });
  filters.genders.length > 0 && transformed.groups.push({ text: 'Gender', items: filters.genders });
  filters.ethnicities.length > 0 && transformed.groups.push({ text: 'Ethnicities', items: filters.ethnicities });
  filters.nationalities.length > 0 && transformed.groups.push({ text: 'Nationalities', items: filters.nationalities });
  filters.hairColors.length > 0 && transformed.groups.push({ text: 'Hair Color', items: filters.hairColors });
  filters.parent.length > 0 && transformed.groups.push({ text: 'Parent', items: filters.parent });
  filters.interests.length > 0 && transformed.groups.push({ text: 'Interests', items: filters.interests });
  (filters.actualAgeMin || filters.actualAgeMax) &&
    transformed.groups.push({ text: 'Actual Age', items: [`${filters.actualAgeMin}-${filters.actualAgeMax}`] });
  (filters.canPlayAgeMin || filters.canPlayAgeMax) &&
    transformed.groups.push({ text: 'Can Play Age', items: [`${filters.canPlayAgeMin}-${filters.canPlayAgeMax}`] });
  return transformed;
};
export default filterTransformer;
